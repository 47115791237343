<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback /> {{ $t('Report Commission Emp') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="exportLoading"
          :disabled="exportLoading"
          class="d-none d-md-block"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters @onSendDate="addPayload" />
      <v-row class="px-2 mb-2">
        <v-col cols="12" md="6" class="py-0" lg="3">
          <v-select
            v-model.trim="selectTeam"
            :items="teamsAndUserList"
            :label="$t('team_select')"
            item-text="teams_name"
            item-value="teams_id"
            dense
            outlined
            hide-details
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        item-key="number"
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.order_id`]="{ item }">
          <router-link
            v-if="item.order_id_pri"
            :to="{ name: 'order-detail', params: { id: item.order_id_pri } }"
            class="font-weight-medium text-decoration-none"
            target="_blank"
          >
            {{ item.order_id }}
          </router-link>
        </template>
        <template v-slot:[`item.order_date`]="{ item }">
          {{ item.order_date | sumdatetime }}
        </template>
      </v-data-table>
    </v-card>
    <v-card class="mt-3">
      <v-data-table
        :headers="columns2"
        :items="dataTableList2"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        item-key="number"
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import Comeback from '../Comeback.vue'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { i18n } from '@/plugins/i18n'
import { reportCommission, removeComma } from '../useExcel'
import { sumdate, sumdatetime } from '@/plugins/filters'
import teamReport from '@/api/teamReport'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'

export default {
  components: {
    Comeback,
    DateFilters,
    ConverseDate,
  },
  filters: { sumdatetime },
  setup() {
    const XLSX = require('xlsx')
    const columns = ref([
      { text: '#', value: 'id', width: 50 },
      { text: i18n.t('receipt_code'), value: 'order_id', width: 120 },
      { text: i18n.t('staff'), value: 'teams_name', width: 300 },
      { text: i18n.t('customer'), value: 'customer_name', width: 200 },
      {
        text: i18n.t('payment'),
        value: 'type_pay',
        width: 180,
        align: 'end',
      },
      { text: i18n.t('date_of_receipt'), value: 'order_date', width: 170 },
      {
        text: i18n.t('net_amount'),
        value: 'amount',
        width: 200,
        align: 'end',
      },
      { text: i18n.t('tag'), value: 'tags', width: 180 },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(true)
    const header = ref('')
    const payload = ref({})
    const selectTeam = ref('')
    const taxSelect = ref('')
    const searchtext = ref('')
    const pointSelect = ref('')
    const teamsAndUserList = ref([])
    const exportLoading = ref(false)
    const price_sum_pay = ref('')
    const datateam = ref('')

    const columns2 = ref([
      {
        text: i18n.t('staff_team'),
        value: 'teams_name',
        width: 120,
      },
      {
        text: i18n.t('deposit'),
        value: 'deposit',
        width: 300,
        align: 'end',
      },
      {
        text: i18n.t('sell'),
        value: 'sell',
        width: 200,
        align: 'end',
      },
      {
        text: i18n.t('total_income'),
        value: 'total_income',
        width: 180,
        align: 'end',
      },
    ])
    const dataTableList2 = ref([])

    const addPayload = (start, end) => {
      payload.value = {
        start,
        end,
        teams_id: selectTeam.value,
        lang: i18n.locale,
      }
      serachReport(payload.value)
    }

    teamReport.teamReportList().then(res => {
      teamsAndUserList.value = res
      teamsAndUserList.value.unshift({
        teams_id: '',
        teams_name: i18n.t('all'),
      })
    })

    const serachReport = async payload => {
      loading.value = true
      const { data, price_sum_pay: sum, datateam } = await reportCommission(payload)
      dataTableList.value = data
      price_sum_pay.value = sum
      dataTableList2.value = datateam
      options.value.page = 1
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const { start, end } = payload.value
      const fileName = `${i18n.t('EmployeeSalesSummary')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t(
        'to',
      )} ${sumdate(end)} .xlsx`

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      //  รายงานวิเคราะห์รายการตรวจ //;
      let dataExport = dataTableList.value.map((item, i) => {
        delete item.order_id_pri

        const teams_name = item.teams_name ? `${item.teams_name}` : '-'

        return {
          number: i + 1,
          ...item,
          teams_name,
          tags: `${item.tags}`,
        }
      })

      dataExport.push({
        order_date: i18n.t('total'),
        amount: price_sum_pay.value,
      })
      dataExport.push({
        number: i18n.t('staff'),
        order_id: i18n.t('deposit'),
        teams_name: i18n.t('sell'),
        customer_name: i18n.t('total_income'),
      })
      for (const item of dataTableList2.value) {
        const mock = {
          number: item.teams_name,
          order_id: item.deposit,
          teams_name: item.sell,
          customer_name: item.total_income,
        }
        dataExport.push(mock)
      }
      dataExport = await removeComma(JSON.parse(JSON.stringify([...dataExport, {}, {}, ...dataTableList2.value])))
      const Heading = [
        [`${i18n.t('EmployeeSalesSummary')} ${i18n.t('since')} ${sumdate(start)} ${i18n.t('to')} ${sumdate(end)} `],
        [
          '#',
          `${i18n.t('receipt_code')}`,
          `${i18n.t('staff')}`,
          `${i18n.t('customer')}`,
          `${i18n.t('payment')}`,
          `${i18n.t('date_of_receipt')}`,
          `${i18n.t('net_amount')}`,
          `${i18n.t('tag')}`,
        ],
      ]

      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: ['number', 'order_id', 'teams_name', 'customer_name', 'type_pay', 'order_date', 'amount', 'tags'],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }]
      const wscols = [
        { wch: 12 },
        { wch: 15 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, i18n.t('EmployeeSalesSummary'))

      // พิมร์ files //;
      XLSX.writeFile(wb, fileName)

      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    watch([selectTeam], () => {
      addPayload(payload.value.start, payload.value.end)
    })

    return {
      columns,
      dataTableList,
      options,
      loading,
      header,
      payload,
      selectTeam,
      taxSelect,
      searchtext,
      pointSelect,
      teamsAndUserList,
      exportLoading,
      price_sum_pay,
      datateam,
      addPayload,
      serachReport,
      exportExcel,
      columns2,
      dataTableList2,
      mdiFileExcelOutline,
    }
  },
}
</script>
